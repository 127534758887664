import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`ALGERIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Mice Groupe SARL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Karim Benmiloud`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +33 (0) 664 633 434 (Switzerland)`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +33 (0) 434 883 460 (France)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +33 (0) 434 883 409`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://mice-groupe.com"}}>{`www.mice-groupe.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`ARMENIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy `}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`AUSTRALIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Medical Synergies`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Ross Horley`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +61 8 9386 9888`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +61 8 9386 9388`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://medicalsynergies.com.au"}}>{`www.medicalsynergies.com.au`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Mediquip Pty Ltd.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Bronwyn O'Hara`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +61 1300 246 349`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +61 1300 246 329`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://mediquip.com.au"}}>{`www.mediquip.com.au`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`AZERBAIJAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`BAHRAIN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Leader Healthcare`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sonal Sachdev`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9714 3263980 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9714 3263984 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9712 6392005 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9712 6343305 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://leaderhealthcaregroup.com"}}>{`www.leaderhealthcaregroup.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`BELARUS`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag>{` `}</MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`BELGIUM`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`TWIN MEDICAL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Jérôme ESTEVES`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +33 (0) 695189166`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://twin-medical.com"}}>{`www.twin-medical.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`BOTSWANA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Survival Technology Pty Ltd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Janine O'Donnell`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +27 11 792 2190`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +27 11 793 4234`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://survivaltechnology.com"}}>{`www.survivaltechnology.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`BRAZIL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Civiam Comercio Importacao Exportacao Ltda.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Mauricio Della Rosa`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +55 11 3884 2423`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +55 11 3884 4250`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://civiam.com.br"}}>{`www.civiam.com.br`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`CHINA (MAINLAND)`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Master Meditech`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: David Wong`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +86-20-87534675`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://master-meditech.com"}}>{`www.master-meditech.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Tangent Medical Technology Co., Ltd.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Yoyo Liang`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel.  +86 21-6211-0899`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax. +86 21-5237-0379`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://tangentmed.cn"}}>{`www.tangentmed.cn`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Tellyes Scientific Inc.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Toni Wang`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +86 22 83711066 ext. 8593`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +86-22-83711065`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://tellyes.com"}}>{`www.tellyes.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`CZECH REPUBLIC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Medim spol. s.r.o.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Michal Pelanek`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +420 545 235 668`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +420 545 235 138`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://medim.info"}}>{`www.medim.info`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`ECUADOR`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Sociedad Radiotecnica Ecuatoriana`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Giovanni Rota`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +593-2-225-2731`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://socradec.com"}}>{`www.socradec.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`FRANCE`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Mice Groupe SARL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Karim Benmiloud`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +33 (0) 664 633 434 (Switzerland)`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +33 (0) 434 883 460 (France)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +33 (0) 434 883 409`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://mice-groupe.com"}}>{`www.mice-groupe.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`TWIN MEDICAL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Jérôme ESTEVES`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +33 (0) 695189166`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://twin-medical.com"}}>{`www.twin-medical.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`HONG KONG`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Tellyes Scientific Inc.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Toni Wang`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +86 22 83711066 ext. 8593`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +86 22 83711065`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://tellyes.com"}}>{`www.tellyes.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`HUNGARY`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Speeding Kft.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Farkas Eszter`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +36 1 214 8858`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://speeding.hu"}}>{`www.speeding.hu`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`INDIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Emergent Medi-Tech Pvt. Ltd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Anil Hebbar`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +91 (022) 67825555 /34`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +91 (022) 25135169`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://emergent.in"}}>{`www.emergent.in`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`ISRAEL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Lapidot Medical Import and Marketing Ltd.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Barak Ziv-Av`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +972-74-7797853`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +972-4-6309631`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://lapidotmedical.com"}}>{`www.lapidotmedical.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`ITALY`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Virtual Training Support`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Francesco Demi`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +39 055 7954253`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +39 055 7954280`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://vits.it"}}>{`www.vits.it`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`JAPAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Nihon Light Service Inc.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Hiroshi Baba`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +81 03 3815 2335`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +81 03 3818 6843`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://nlsinc.co.jp"}}>{`www.nlsinc.co.jp`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`JORDAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Beta Scientific Instruments Co.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Amro Yousef`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +966 11 206 0433`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +966 11 206 0954`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://beta-si.com.sa"}}>{`www.beta-si.com.sa`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`KAZAKHSTAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`KUWAIT`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Leader Healthcare`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sonal Sachdev`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9714 3263980 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9714 3263984 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9712 6392005 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9712 6343305 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://leaderhealthcaregroup.com"}}>{`www.leaderhealthcaregroup.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`KYRGYZSTAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`MACAU`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Tellyes Scientific Inc.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Toni Wang`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +86 22 83711066 ext. 8593`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +86 22 83711065`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://tellyes.com"}}>{`www.tellyes.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`MALAYSIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Indizium Sdn Bhd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: James Wong`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +603 7842 8848`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +603 7842 9848`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://indizium.com"}}>{`www.indizium.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Insan Saintifik Sdn Bhd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Muhamad Azahar`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +603 7651 7719`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: + 603 7651 7701`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://insansaintifik.com"}}>{`www.insansaintifik.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`United Akrab Tech Sdn Bhd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Louis Teo`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +65 6305 2539`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: + 65 6305 2549`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://unitedbmec.com"}}>{`www.unitedbmec.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`MEXICO`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Taq Sistemas Medicos`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Diana Rodriguez Contreras`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +52 55 5488 9898 ext. 124`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +52 55 5575 9190`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://taq.com.mx"}}>{`www.taq.com.mx`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`MOLDOVA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Medical Simulator Projects SRL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Diana Anghelescu`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: + 4021 348 5272`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +40372 872 626`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"www.medicalsimulatorprojects.ro/"}}>{`www.medicalsimulatorprojects.ro/`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {};

  