import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`NAMIBIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Survival Technology Pty Ltd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Janine O'Donnell`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +27 11 792 2190`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +27 11 793 4234`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://survivaltechnology.com"}}>{`www.survivaltechnology.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`NEW ZEALAND`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Medical Synergies`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Ross Horley`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +61 8 9386 9888`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +61 8 9386 9388`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://medicalsynergies.com.au"}}>{`www.medicalsynergies.com.au`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Mediquip Pty Ltd.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Bronwyn O'Hara`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +61 1300 246 349`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +61 1300 246 329`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://mediquip.com.au"}}>{`www.mediquip.com.au`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`OMAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Leader Healthcare`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sonal Sachdev`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9714 3263980 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9714 3263984 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9712 6392005 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9712 6343305 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://leaderhealthcaregroup.com"}}>{`www.leaderhealthcaregroup.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`PERU`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`WAREM`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Ronal Sotomayor Torres`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +511 452 3328`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://warem.pe"}}>{`www.warem.pe`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`PHILIPPINES`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Martin Laburada`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +63 2 726 5324`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://aspensystem.com.ph"}}>{`https://aspensystem.com.ph`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`POLAND`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Simedu Sp. z o.o.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Miroslaw Kostyra`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +48 781 600 865`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://simedu.pl"}}>{`www.simedu.pl`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`PORTUGAL (Mobile Line)`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`More Than Simulators S.L.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Maria Llorca`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +34 673 354 731`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`QATAR`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Leader Healthcare`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sonal Sachdev`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9714 3263980 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9714 3263984 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9712 6392005 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9712 6343305 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://leaderhealthcaregroup.com"}}>{`www.leaderhealthcaregroup.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`ROMANIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Medical Simulator Projects SRL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Diana Anghelescu`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: + 40 21 348 5272`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +40 372 872 626`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"www.medicalsimulatorprojects.ro/"}}>{`www.medicalsimulatorprojects.ro/`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`RUSSIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`SAN MARINO`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Virtual Training Support`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Francesco Demi`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +39 055 7954253`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +39 055 7954280`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://vits.it"}}>{`www.vits.it`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`SAUDI ARABIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Beta Scientific Instruments Co.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Amro Yousef`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +966 11 206 0433`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +966 11 206 0954`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://beta-si.com.sa"}}>{`www.beta-si.com.sa`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`SINGAPORE`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Indizium Pte Ltd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: James Wong`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +65 6827 9699`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +65 6827 9601`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://indizium.com"}}>{`www.indizium.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Insan Saintifik Sdn Bhd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Muhamad Azahar`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +603 7651 7719`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: + 603 7651 7701`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://insansaintifik.com"}}>{`www.insansaintifik.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`United BMEC Pte Ltd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Louis Teo`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +65 6305 2539`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: + 65 6305 2549`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://unitedbmec.com"}}>{`www.unitedbmec.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`SLOVAKIA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Medim spol. s.r.o.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Michal Pelanek`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +420 545 235 668`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +420 545 235 138`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://medim.info"}}>{`www.medim.info`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`SOUTH AFRICA`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Survival Technology Pty Ltd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Janine O'Donnell`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +27 11 792 2190`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +27 11 793 4234`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://survivaltechnology.com"}}>{`www.survivaltechnology.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`SPAIN (Mobile Line)`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`More Than Simulators S.L.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Maria Llorca`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +34 673 354 731`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`SWITZERLAND`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Mice Groupe SARL - Switzerland`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Karim Benmiloud`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +33 (0) 664 633 434`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +33 (0) 434 883 409`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://mice-groupe.com"}}>{`www.mice-groupe.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`TAIWAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`KYS Technology Co., Ltd.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Paul Chuan`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +886 (2) 29115233`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +886 (2) 29116855`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://kyst.com.tw"}}>{`www.kyst.com.tw`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Tellyes Scientific Inc.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Toni Wang`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +86 22 83711066 ext. 8593`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +86 22 83711065`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://tellyes.com"}}>{`www.tellyes.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`TAJIKISTAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`THAILAND`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Upright Healthcare Co. Ltd.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Wiwat Kaeopraphatson`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +66 (0) 2 181 7673`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +66 (0) 2 181 7674`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://upright-groups.com"}}>{`www.upright-groups.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`United BMEC (THAI) Co., Ltd`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Louis Teo`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +65 6305 2539`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: + 65 6305 2549`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://unitedbmec.com"}}>{`www.unitedbmec.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`TURKMENISTAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy `}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`UNITED ARAB EMIRATES`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Leader Healthcare`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sonal Sachdev`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9714 3263980 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9714 3263984 (Dubai)`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +9712 6392005 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +9712 6343305 (Abu Dhabi)`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://leaderhealthcaregroup.com"}}>{`www.leaderhealthcaregroup.com`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`UZBEKISTAN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GEOTAR-Med LLC`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Sergei Zaslavskiy`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +7 (903) 566-99-21`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://geotar-med.ru"}}>{`www.geotar-med.ru`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`VATICAN CITY`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Virtual Training Support`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Francesco Demi`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +39 055 7954253`}</MDXTag>
<MDXTag name="p" components={components}>{`Fax: +39 055 7954280`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://vits.it"}}>{`www.vits.it`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`AFRICA (excluding South Africa), AUSTRIA, DENMARK, FINLAND,GERMANY, GREECE, IRAN, IRAQ, IRELAND, LEBANON, NETHERLANDS, NORWAY, PAKISTAN, POLAND, PORTUGAL, SPAIN, SOUTH KOREA, SWEDEN, TURKEY`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3B Scientific GmbH`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Contact: Michael Leske`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +49 (0) 40 739 66 221`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"http://3bscientific.com"}}>{`www.3bscientific.com`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {};

  