import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import Column1 from '../../content/contact/internationalDistributorsC1.mdx';
import Column2 from '../../content/contact/internationalDistributorsC2.mdx';

const Contact = ({ data }) => (
  <Layout>
    <Head pageTitle={data.contactJson.title} />
    <Box fill="horizontal">
      <Box width="xlarge" alignSelf="center">
        <SectionTitle title="International Distributors" />
        <Box alignSelf="center" direction="row-responsive">
          <Box width="medium" margin="small">
            <Column1 />
          </Box>
          <Box width="medium" margin="small">
            <Column2 />
          </Box>
        </Box>
      </Box>
    </Box>
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query internationalDistributorQuery {
    contactJson {
      title
    }
  }
`;
